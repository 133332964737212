@import "../../style/variables";
@import "../../style/typography";

.error-page {
  @include sm {
    h1 {
      font-size: 2.5em;
    }

    h2 {
      font-size: 2em;
      line-height: 1.5em;
    }

    .sleeping-moshi {
      width: 10em;
      margin-bottom: 7em;
      margin-top: 5em;
    }
  }

  h1 {
    @include font-heading;
    color: $color-primary-dark;
    padding-bottom: 2rem;
  }

  h2 {
    @include font-regular;
    padding-bottom: 1.6rem;
  }

  .sleeping-moshi {
    width: 13em;
    margin-bottom: 7em;
    margin-top: 2em;
  }
}
