@import "../../../../../shared/style/variables";

.content-grid {
  $screen-width-with-four-columns: 1137px;

  display: grid;
  grid-template-columns: repeat(auto-fit, 206px);
  grid-row-gap: 24px;
  grid-column-gap: 24px;
  justify-content: start;
  @media (max-width: #{$screen-width-with-four-columns}) {
    justify-content: space-between;
  }
  margin: 0 auto;
  list-style: none;
}
