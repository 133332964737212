@import "../../../../shared/style/typography";

.player-page {
  &.bedtime {
    border-radius: 0;
    padding-bottom: 3rem;
  }
  padding-bottom: 3rem;
  .bedtime-story-title {
    margin: 3rem 0 2rem;
    p {
      font-size: 22px;
    }
  }
  .component-box.with-header {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 1024px;
    @include md {
      padding: 0 63px;
    }
    .component-box-header {
      margin-left: -46px;
      padding-bottom: 1.6rem;
      display: flex;
      align-items: flex-start;
      .back {
        cursor: pointer;
        background-color: $color-primary-dark;
        padding: 5px 7px 5px 4px;
        width: 23px;
        height: 24px;
        border-radius: 50%;
        margin-left: 22px;
      }
    }
    .container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: fit-content(250px) 1fr;
      gap: 24px 24px;
      padding-bottom: 32px;
      @include sm {
        grid-template-areas:
          "player player"
          "details details"
          "resources resources"
          "track-list track-list";
      }
      @include lg {
        grid-template-areas:
          "player details"
          "player resources"
          "track-list track-list";
      }
    }
    .player {
      grid-area: player;
    }
    .details {
      grid-area: details;
    }
    .playlist-details {
      grid-area: details;
    }
    .playlist-resources {
      grid-area: resources;
    }
    .lesson-plans {
      grid-area: track-list;
    }
    .track-list {
      grid-area: track-list;
      grid-column: 1/3;
    }
  }
}

.below-player-page {
  margin: 4rem 0;
  overflow-x: hidden;
  .download-widget {
    margin-bottom: 4rem;
    #ga-download-google-play {
      margin: 0 1rem;
    }
  }
  .try-moshi-image-wrapper {
    margin-bottom: 8rem;
  }
}
