.promo-redeem-page {
  .component-box.with-header {
    display: flex;
    flex-direction: column;
    height: 100%;

    .component-box-header {
      margin-bottom: 1em;
    }
  }
}
