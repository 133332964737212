@import "../../../../shared/style/typography";

.player-menu {
  padding-bottom: 8rem;
  .component-box.with-header {
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    height: 100%;
    padding-top: 0;
    padding-bottom: 4.2rem;
  }

  .content {
    padding: 0 64px;
    h1 {
      @include font-heading;
      font-size: 30px;
      text-align: left;
    }
    .content-info {
      @include font-sub-heading;
      font-size: 24px;
      max-width: 60%;
      margin: 71px auto;
    }

    th:nth-of-type(2) {
      padding-left: 25px;
      @include lg {
        width: 284px;
      }
      width: 220px;
      border-radius: 24.5px 0 0 24.5px;
    }

    th:nth-last-of-type(2) {
      border-radius: 0 24.5px 24.5px 0;
    }
  }
}
