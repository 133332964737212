@import "../../../../../shared/style/typography";

.content-row-header {
  display: flex;
  justify-content: space-between;
  margin: 32px 0 16px;
}
.content-grid {
  &.group {
    max-height: 314px;
    overflow: hidden;
  }
  &.expanded {
    max-height: unset;
  }
  h3 {
    @include font-sub-heading;
    text-align: left;
    font-size: 24px;
    max-width: 60%;
  }
}
