$screen-sm-min: 280px;
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

$screen-md-min: 661px;
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

$screen-lg-min: 1126px;
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

$screen-xl-min: 1400px;
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

$screen-short-max: 800px;
@mixin short {
  @media (max-height: #{$screen-short-max}) {
    @content;
  }
}

/*
  Sizes
 */
$width-logo-mobile: 80px;
$width-logo-desktop: 133px;

$height-navigation-mobile: 80px;
$height-navigation-desktop: 120px;

$height-icon-button: 34px;

/*
* Colours from brand guidlines
*/

$color-blue-1: #06a9ce;
$color-blue-2: #72d7f2;
$color-blue-3: #c8f4ff;

$color-purple-1: #8d6dea;
$color-purple-2: #b6abfe;
$color-purple-3: #f1e9ff;

$color-teal-1: #00c8b1;
$color-teal-2: #91e7dc;
$color-teal-3: #e1fff6;

$color-orange-1: #ff8600;
$color-orange-2: #ffaf57;
$color-orange-3: #fde9c5;

$color-green-1: #41c978;
$color-green-2: #82dda7;
$color-green-3: #dff3c9;

$color-black: #000000;
$color-dark-blue: #010b3b;

$color-white: #ffffff;
$color-off-white: #f9f8f2;

$color-coral: #ff646c;

/*
  Colours
 */
$color-primary-dark: $color-dark-blue;
$color-primary-main: $color-dark-blue;
$color-primary-light: $color-blue-2;

$color-primary-blue: $color-blue-1;

$color-section-pale: $color-purple-3;
$color-section-mid: $color-purple-2;

$color-primary-teal: $color-teal-1;
$color-form-light: $color-blue-3;
$color-form-input: $color-white;

$color-text-dark: $color-dark-blue;
$color-text-blue: $color-blue-2;
$color-text-orange: $color-orange-1;
$color-text-purple: $color-purple-1;
$color-text-teal: $color-teal-1;

$color-text-error: $color-coral;

$color-brand-coral: $color-coral;

$color-button-trial: #f8cf03;
$color-button-play: $color-orange-1;
$color-button-link: $color-dark-blue;
$color-button-coral: $color-coral;
$color-button-secondary: $color-blue-2;

$color-disabled: #cccccc;

$color-icon-default: $color-white;

$color-tag-free: $color-purple-2;
$color-tag-new: $color-coral;

$color-system-alert: $color-coral;
$color-system-warning: $color-orange-1;
$color-system-success: $color-green-1;

$color-box-shadow: #0000004f;

$color-gradient-text-light: #3985cb;

/*
* Gradients
*/

$gradient-breathing-text: linear-gradient(
  to right,
  $color-white 25%,
  $color-gradient-text-light 100%
);
