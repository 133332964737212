@import "variables";
@import "typography";

html {
  font-size: 16px;
  line-height: 1.3em;
}

#root {
  margin: 0;
  padding: 0;
}

body {
  @include font-regular;
  @include font-text;

  font-size: 16px;
  letter-spacing: 0.21px;
  line-height: 19px;
  text-rendering: optimizeLegibility;

  margin: 0;
  padding: 0;

  color: $color-primary-dark;
  background-color: $color-off-white;
}

.app-body {
  display: flex;
  flex-direction: column;
  background-color: $color-primary-light;
  overflow: hidden;

  @include md {
    border-radius: 0 0 70px 70px;
  }
  &.center {
    align-items: center;
    width: 100vw;
  }
  @include sm {
    &.box {
      padding-top: 100px;
      height: 100vh;
    }
  }
  @include md {
    &.box {
      top: 0;
      height: unset;
    }
  }
  &.loading {
    min-height: 20em;
  }

  padding-top: $height-navigation-mobile;
  @include md {
    padding-top: $height-navigation-desktop;
  }
  @include lg {
    padding: $height-navigation-desktop 0 10em 0;
  }

  .component-box-wrap {
    max-width: 100%;
  }

  .component-box {
    position: relative;
    width: 90%;
    max-width: 666px;
    border-radius: 2rem;
    margin-bottom: 3rem;

    @include sm {
      background-color: transparent;
    }
    @include md {
      background-color: $color-form-light;
      border-radius: 4rem;
    }
    box-sizing: border-box;
    z-index: auto;
  }

  .component-box-header {
    @include sm {
      padding: 0 1.5em 1rem 1.5em;
      margin-top: 0;
      .font-heading {
        text-align: center;
        font-size: 26px;
        padding-top: 1rem;
        margin-bottom: 0;
      }
    }
    @include md {
      padding-bottom: 1px;
      margin-top: 1.6em;
      .font-heading {
        font-size: 2.6rem;
        padding-bottom: 1.8rem;
        margin-bottom: 1rem;
      }
    }
    .image {
      position: absolute;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.wide {
    // admin pages
    padding: 7px 3rem;
    .components-wrap {
      box-sizing: border-box;
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;

      @include lg {
        width: 850px;
        padding-left: 0;
        padding-right: 0;
      }

      @include xl {
        width: 1040px;
      }
    }
  }

  .component-after {
    @include font-bold;
    @include sm {
      margin: 1.2rem 2rem 6rem;
    }
    @include md {
      margin: 1.2rem 4rem 6rem;
    }
    @include lg {
      margin: 1.2rem 6rem 6rem;
    }
    margin-bottom: 0;
    text-align: center;
  }
}

.hide-on-mobile {
  display: none;
  @include md {
    display: block;
  }
}

.scrollable {
  max-height: 30rem;
  overflow-x: scroll;
}

.p-1 {
  padding: 1rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: 2rem;
}

.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}

.mt-1 {
  margin-top: 1rem;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.mb-4 {
  margin-bottom: 4em;
}

.ml-1 {
  margin-left: 1rem;
}
.ml-2 {
  margin-left: 2rem;
}

.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mt-4 {
  margin-top: 4em;
}

.mlr-a {
  margin-left: auto;
  margin-right: auto;
}

.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.width-100 {
  width: 100%;
}

.width-80 {
  width: 80%;
}
