@import "../../../../../../shared/style/typography";
@import "../../../../../../shared/style/variables";

.lesson-plans {
  padding-bottom: 4.3em;
  h1 {
    margin-top: 0;
    padding-bottom: 38px;
    @include font-black;
    font-size: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    line-height: 24px;

    background-color: #ffaf57;

    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }

  .plans-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(auto-fill, minmax(1fr, 1fr));

    gap: 24px 22px;
    grid-template-areas:
      ". ."
      ". .";
    grid-auto-flow: row;
  }
}
