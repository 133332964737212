@import "../../../../../../shared/style/variables";
@import "../../../../../../shared/style/typography";

.resource-container {
  height: 64px;
  border: 1px solid $color-primary-dark;
  border-radius: 13px;

  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 2fr 0.25fr 0.25fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "info icon download"
    "title icon download";
  grid-auto-flow: dense;
  .download {
    cursor: pointer;
    grid-area: download;
    width: 34px;
    background-color: $color-primary-dark;
    border-radius: 50%;
  }
  .icon {
    grid-area: icon;
  }
  .title {
    @include font-bold;
    grid-area: title;
    align-self: start;
    margin: 0 0 15px 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .info {
    @include font-regular;
    grid-area: info;
    align-self: end;
    ul {
      display: flex;
      flex-direction: row;
      margin: 12px 0 0 15px;
      li:first-of-type {
        display: inherit;
      }
      li {
        display: list-item;
        color: $color-primary-dark;
        padding-right: 1rem;
      }
    }
  }

  .download,
  .icon {
    height: 34px;
    align-self: center;
    justify-self: center;
  }
  .title,
  .info {
    font-size: 13px;
    text-align: left;
  }
}
