.loading-indicator {
  width: 140px;
  margin: 2em auto;
  .cloud {
    position: relative;
    .foreground {
      position: relative;
    }
    .background {
      position: absolute;
      left: 0;
    }
  }
}
