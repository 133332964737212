@import "../../../../../shared/style/variables";
.play-button {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 18px;
  text-align: center;

  cursor: pointer;
}

.play-button:hover {
  opacity: 0.9;
}
