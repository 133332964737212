@import "../../../shared/style/variables";
@import "../../../shared/style/typography";

.payment-page {
  position: relative;
  white-space: break-spaces;
  .component-box.with-header {
    position: static;
    display: flex;
    flex-direction: column;
    height: 100%;
    @include sm {
      padding-bottom: 2rem;
    }
    @include md {
      padding-bottom: unset;
    }
  }

  .top-title {
    @include font-bold;
    color: $color-primary-dark;
    margin: 0 7rem 0 7rem;
    @include sm {
      padding-bottom: 2rem;
    }
    @include md {
      padding-bottom: 0;
    }
  }

  .subtitle {
    margin: 1rem 1.3em;
    @include md {
      margin: 1rem 10.6rem;
      line-height: 20px;
    }
  }

  .with-loading-block {
    position: static;
    &.loading {
      position: relative;
    }
    .loading-block {
      height: 70%;
    }
  }

  .component-after {
    @include sm {
      margin-left: 1rem;
      margin-right: 1rem;
    }
    @include lg {
      margin-left: 8rem;
      margin-right: 10rem;
    }
    .terms {
      @include font-heading;
      font-size: 14px;
      text-align: left;
    }
    .text-below {
      @include font-regular;
      font-size: 12px;
      text-align: left;
      line-height: 14px;
      margin-top: -0.5em;
    }
  }
}

.payment-confirmation-page {
  transition: background-color 3s ease;
  .component-box.with-header {
    transition: background-color 3s ease;
    h1 {
      line-height: 110%;
      @include sm {
        font-size: 26px;
        margin-left: 4rem;
        margin-right: 4rem;
      }
      @include md {
        font-size: 24px;
        margin-left: 4rem;
        margin-right: 4rem;
      }
    }
    h2 {
      @include font-bold;
      @include sm {
        font-size: 14px;
      }
      @include md {
        font-size: 16px;
      }
    }
    @include md {
      padding: 0 1.6em 1.6em;
      .m-1 {
        margin-bottom: 2.5em;
      }
    }
    .p-1 {
      padding-left: 1.6em;
      padding-right: 1.6em;
    }
    .component-box-header {
      @include md {
        padding: 0 0 1px 0;
      }
      .font-heading {
        @include sm {
          padding-bottom: 0;
        }
        @include md {
          padding-bottom: 1rem;
        }
      }
      .image {
        top: -3.5rem;
        img {
          width: 250px;
        }
      }
    }
    .mobile-section {
      transition: background-color 3s ease;
      border-radius: 30px;
    }
    .copy {
      @include font-text;
      @include font-bold;
      @include sm {
        margin: 1rem 4rem;
      }
      @include md {
        margin: 2rem 8rem 3rem;
      }
    }

    .btn {
      margin: 2rem auto 0;
    }
    .email-text {
      @include font-bold;
      transition: background-color 3s ease;

      padding: 0.8rem 1rem;
      border-radius: 25px;
      line-height: 18px;
    }
  }
  overflow-wrap: break-word;
}
