@import "../../../shared/style/typography";
@import "../../../shared/style/variables";

.alexa {
  padding-top: 6vh !important;

  .component-box.with-header {
    @include md {
      padding: 0 64px 64px;
    }
    .component-box-header {
      padding: 16px 0;
      @include md {
        padding: 32px 0;
      }
    }

    .container {
      position: relative;
      .input {
        margin: 0 auto 2rem;
        @include font-sub-heading;
        width: 78%;
        background-color: $color-off-white;
        color: $color-primary-dark;
        border: 2px solid $color-primary-dark;
        border-radius: 16px;
        height: 48px;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: ". . . .";
      }
      img {
        position: absolute;
        top: 26%;
        left: 93%;
      }
    }
    p {
      display: flex;
      justify-content: center;
      align-items: center;

      margin: 0;
      height: 100%;
    }
  }
}
