@import "../../../../../shared/style/variables";

.search-container {
  display: flex;
  height: 46px;
  margin: 5px 40px 25px;

  .search-input {
    flex-grow: 2;
    background-color: $color-white;
    border: none;
    border-radius: 22px;
    padding: 0 24px;
    margin-right: 24px;
  }
  .search-button {
    display: flex;
    justify-content: center;
    cursor: pointer;

    background-color: $color-coral;

    width: 46px;
    height: 46px;
    border-radius: 46px;
    border: none;
  }
}
