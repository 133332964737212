@import "../../../../shared/style/variables";

$rhap_theme-color: $color-primary-dark !default;
$rhap_background-color: $color-white !default;
$rhap_bar-color: $color-primary-light !default;
$rhap_time-color: $color-primary-dark !default;

@import "../../../../../node_modules/react-h5-audio-player/src/styles";

.player {
  margin: auto;
  @include sm {
    width: 100%;
  }
  @include md {
    width: 440px;
    min-height: 440px;
  }
  height: 360px;
  position: relative;
  .background {
    height: 360px;
    width: 99.9%; // slightly below 100% to avoid aliasing on rounded corners
    object-fit: cover;
    border-radius: 25px;
  }
  .lottie {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    position: relative;
    width: 100%;
    height: 440px;
    border-radius: 25px;
    overflow: hidden;
    &.hidden {
      display: none;
    }
    svg {
      width: 102% !important;
      height: 102% !important;
    }
  }
  .rhap_container {
    position: absolute;
    width: 100%;
    bottom: 0;
    border-radius: 0 0 25px 25px;
    padding: 13px 24px;
    box-shadow: 0 0 0 0;
    .rhap_time {
      font-size: 13px;
    }
    .rhap_progress-indicator {
      height: 16px;
      width: 16px;
      margin-top: 2px;
      box-shadow: 0 0 0 0;
    }
    .rhap_volume-indicator {
      height: 10px;
      width: 10px;
    }
    .rhap_progress-bar {
      height: 4px;
    }
    .rhap_progress-filled {
      background-color: $color-primary-dark;
    }
    .rhap_volume-bar {
      height: 2px;
    }
    .rhap_controls-section {
      flex-direction: row-reverse;
      .rhap_additional-controls {
        justify-content: flex-end;
      }
      .rhap_main-controls {
        height: 60px;
        width: 174px;
        justify-content: space-between;
        .rhap_play-pause-button {
          height: 51px;
          width: 51px;
        }
        .rhap_forward-button,
        .rhap_rewind-button {
          height: 46px;
        }
      }
      .rhap_volume-controls {
        visibility: hidden;
      }
    }
  }
  &.floating {
    position: unset;
    .rhap_container {
      position: fixed;
      max-width: 772px;
      height: 114px;

      left: 0;
      right: 0;
      bottom: 16px;
      margin: auto;

      border-radius: 25px;
    }
  }
}
