@import "../../../shared/style/variables";

.awards-wrapper {
  display: none;
  margin: auto;
  padding-bottom: 2rem;
  @include md {
    display: flex;
    justify-content: flex-start;
  }
  @include lg {
    margin: inherit;
  }
  flex-direction: column;
  a {
    margin-top: 0;
  }
  .awards {
    display: flex;
    padding-bottom: 4rem;
    height: 45px;
  }
  #go-landing-awards {
    height: 60px;
  }
  .award {
    margin-right: 1.6rem;
    width: auto;
    @include md {
      max-height: 60px;
    }
    /* Fix svg width calculations on Safari 11+ */
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) and (stroke-color: transparent) {
        max-width: 174px;
      }
    }
  }
}
