@import "../../../../../shared/style/variables";
@import "../../../../../shared/style/typography";

.try-moshi-image-wrapper {
  position: relative;
  .try-moshi-feature-card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include sm {
      padding: 1rem 30px 2.3rem;
    }
    @include lg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    @include xl {
      left: 0;
    }
    z-index: 1;

    .try-moshi-feature-card {
      display: flex;
      flex-direction: column;
      justify-content: center;

      width: 104px;
      height: 104px;
      border-radius: 25px;

      margin: auto;
      @include md {
        width: 158px;
        height: 158px;
      }
      @include lg {
        width: 200px;
        height: 200px;
      }
      p {
        @include font-heading;
        @include sm {
          font-size: 16px;
        }
        @include md {
          padding: 0 1rem;
          font-size: 18px;
        }
        @include lg {
          font-size: 24px;
        }
        text-align: center;
        line-height: 110%;
        margin: auto 0.6rem;
      }
    }
    .try-moshi-feature-card:nth-of-type(1) {
      @include lg {
        transform: translateX(-115%) rotate(-20deg);
      }
    }
    .try-moshi-feature-card:nth-of-type(2) {
      @include lg {
        transform: translateX(115%) rotate(21deg);
      }
    }
    .try-moshi-feature-card:nth-of-type(3) {
      @include lg {
        transform: translateX(-115%) rotate(-21.85deg);
      }
    }
  }
}
