@import "../../../../../shared/style/variables";
@import "../../../../../shared/style/typography";
@import "../../../../../shared/style/animations";

.content-filter {
  padding: 5px 40px 25px;
  text-transform: capitalize;

  .filter-info {
    @include font-bold;
    text-align: left;
    font-size: 16px;
    padding-bottom: 15px;
    text-transform: none;
  }
  .filter-selection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 16px;

    .drop-down {
      width: 206px;

      .drop-down-title {
        position: relative;
        .drop-down-arrow {
          transition: all ease-in-out 0.1s;
        }
      }

      &.open {
        .drop-down-title {
          .drop-down-arrow {
            rotate: -180deg;
          }
        }
      }
    }
  }
  .applied-filters {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    min-height: 52px;
    margin-top: 32px;

    .filter {
      @include fade;
      display: flex;
      gap: 10px;
      justify-content: flex-end;
      align-items: center;

      transition: all 0.2s ease-out;

      position: relative;
      color: $color-white;
      background-color: $color-primary-dark;
      border-radius: 24px;
      cursor: pointer;
      height: 38px;
      line-height: 38px;

      padding: 0 6px 0 16px;
      margin-right: 16px;
      margin-bottom: 16px;

      font-size: 13px;
      &.all {
        padding-right: 16px;
      }
      .close-container {
        background-color: $color-white;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        .close {
          position: absolute;
          right: 14px;
          width: 11px;
          height: 11px;
          top: 14px;
        }
        .close:before,
        .close:after {
          position: absolute;
          left: 5px;
          content: " ";
          height: 11px;
          width: 1.5px;
          background-color: $color-primary-dark;
        }
        .close:before {
          transform: rotate(45deg);
        }
        .close:after {
          transform: rotate(-45deg);
        }
      }
    }
    .clear-all {
      font-size: 15px;
      cursor: pointer;
      margin: auto 0 25px;
    }
  }
}

.content-filter > .filter-selection {
  margin: 12px;
}
.content-filter > .filter-selection:nth-of-type(1) {
  margin-left: 0;
}
