@import "../../../../../shared/style/typography";

.review-card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 15px;

  @include sm {
    height: 440px;
    margin: 0 auto;
  }
  @include md {
    width: 380px;
    height: 560px;
    justify-content: space-between;
    border-radius: 45px;
  }
  @include lg {
    width: 384px;
    height: 597px;
  }

  scroll-snap-align: center;

  &.prev {
    transform: translateY(60px) rotate(5deg);
  }
  &.next {
    transform: translateY(60px) rotate(-5deg);
  }

  .quote {
    @include font-heading;
    @include sm {
      font-size: 48px;
      margin: 0 0 -1rem;
    }
    @include md {
      font-size: 72px;
      margin: 2rem 0 -2rem;
    }
  }

  .review {
    @include font-heading;
    @include sm {
      font-size: 18px;
    }
    @include md {
      font-size: 28px;
    }
    line-height: 120%;
  }
  .author {
    @include font-heading;
    @include sm {
      font-size: 18px;
    }
    @include md {
      font-size: 22px;
    }
  }
  .age {
    @include font-text;
    padding-bottom: 2rem;
    margin-top: 0;
  }
  .author {
    margin-bottom: 0.3rem;
  }

  h1,
  p {
    text-align: center;
    @include sm {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    @include md {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
}
