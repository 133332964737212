@import "../../../shared/style/_typography";

.flow-indicator {

  @include font-bold;
  display: flex;
  align-items: center;
  justify-content: center;

  .step-number {
    @include sm {
      @include small;
    }
    @include md {
      @include large;
    }
  }
}
