@mixin fadeIn {
  animation: fadeIn 0.2s linear;
  -webkit-animation: fadeIn 0.2s linear;
  -moz-animation: fadeIn 0.2s linear;
  -o-animation: fadeIn 0.2s linear;
  -ms-animation: fadeIn 0.2s linear;
  @keyframes fadeIn {
    0% {
      visibility: hidden;
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      visibility: hidden;
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      visibility: hidden;
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      visibility: hidden;
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      visibility: hidden;
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }
}
.fade-in {
  @include fadeIn;
}

@mixin fadeOut {
  animation: fadeOut 0.2s linear;
  -webkit-animation: fadeOut 0.2s linear;
  -moz-animation: fadeOut 0.2s linear;
  -o-animation: fadeOut 0.2s linear;
  -ms-animation: fadeOut 0.2s linear;
  @keyframes fadeOut {
    0% {
      visibility: visible;
      opacity: 1;
    }
    100% {
      visibility: hidden;
      opacity: 0;
    }
  }

  @-moz-keyframes fadeOut {
    0% {
      visibility: visible;
      opacity: 1;
    }
    100% {
      visibility: hidden;
      opacity: 0;
    }
  }

  @-webkit-keyframes fadeOut {
    0% {
      visibility: visible;
      opacity: 1;
    }
    100% {
      visibility: hidden;
      opacity: 0;
    }
  }

  @-o-keyframes fadeOut {
    0% {
      visibility: visible;
      opacity: 1;
    }
    100% {
      visibility: hidden;
      opacity: 0;
    }
  }

  @-ms-keyframes fadeOut {
    0% {
      visibility: visible;
      opacity: 1;
    }
    100% {
      visibility: hidden;
      opacity: 0;
    }
  }
}
.fade-out {
  @include fadeOut;
}

@mixin fade {
  transition: opacity 0.2s ease-out;
  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-out;
  -ms-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;

  visibility: hidden;
  opacity: 0;
}
.fade {
  @include fade;
}
