@import "../../../../shared/style/variables";

.promotion-select {
  @include sm {
    margin-top: 2rem;
  }
  @include md {
    margin-top: 0;
    margin-left: 7.2em;
    margin-right: 7.2em;
  }
  margin-left: 1.1em;
  margin-right: 1.1em;
  .select-button {
    @include sm {
      margin-bottom: 1em;
    }
    @include md {
      margin-bottom: 1.4em;
    }
  }
}
