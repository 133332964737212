@import "../../../../../../shared/style/variables";
@import "../../../../../../shared/style/typography";

.playlist-details {
  text-align: left;
  display: flex;
  flex-direction: column;
  color: $color-primary-dark;

  .title {
    @include font-heading;
    font-size: 30px;
  }
  .description {
    @include font-text;
    font-size: 18px;
  }
  .tags {
    display: flex;
    gap: 12px;
    .pill {
      @include font-button;
      background-color: white;
      border-radius: 17px;
      padding: 8px 11px;
      font-size: 15px;
      margin-top: 8px;
    }
  }
}
