@import "../../../../shared/style/typography";

.payment-form {
  .hosted-input {
    position: relative;
    margin: 19px 0 12px;
    .error-icon {
      top: 12px;
    }
    .error-message {
      margin-top: 6px;
    }
  }
  #paypal-button {
    &:not(:empty):after {
      @include font-regular;
      content: "or";
      text-align: center;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.2px;

      margin: 0.5em;
      padding-bottom: 0.5em;
      display: block;
    }
  }
  #apple-pay-container {
    &:not(:empty):after {
      text-align: center;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.2px;
      padding-bottom: 20px;
      margin: 0.5em;
      display: block;
    }
  }
  .help-cvv {
    overflow: hidden;
    transition: max-height 0.2s ease-in, opacity 0.2s ease;
    &.closed {
      max-height: 0;
      opacity: 0;
    }
    &.open {
      max-height: 134px;
      opacity: 1;
    }
  }
  .btn {
    @include md {
      margin-top: 1.6rem;
    }
  }
}

.apple-pay-button {
  display: block;
  width: 100%;
  min-width: 100px;
  height: 50px;
  margin: 0.1em 0.1em 0.5rem;
  border-radius: 25px;
  background-image: -webkit-named-image(apple-pay-logo-white);
  background-color: black;
  background-position: 50% 50%;
  background-size: 60%;
  background-repeat: no-repeat;
  border-style: none;
  &:hover {
    cursor: pointer;
    background-color: rgb(240, 240, 240);
  }
}
