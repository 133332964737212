@import "../../../shared/style/typography";
@import "../../../shared/style/variables";

.navigation {
  @include x-large;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: right;
  z-index: 7;
  &.open {
    position: fixed;
    height: 100%;
  }
  .navigation-content {
    height: $height-navigation-mobile;
    @include md {
      height: $height-navigation-desktop;
    }
    padding: 0 1.6rem;
    @include md {
      padding: 0 5rem;
    }
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    margin-left: auto;
    margin-right: auto;

    line-height: 33.6px;

    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    &.open {
      background-color: $color-white;
    }
  }
}
