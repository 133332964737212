@import "../../../../../../shared/style/variables";
@import "../../../../../../shared/style/typography";

.playlist-track-container {
  @include sm {
    padding-left: 16px;
  }
  @include lg {
    padding-left: 0;
  }
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 0.5fr 2fr 0.5fr;
  grid-template-rows: 1fr;
  grid-template-areas: "image info icons";
  gap: 16px;
  align-items: center;

  max-width: 100%;
  height: 140px;
  border-radius: 25px;
  background-color: transparent;
  color: $color-primary-dark;
  &.playing {
    background-color: $color-primary-light;
  }

  .icons {
    grid-area: icons;
    justify-self: end;
    padding-right: 32px;
    .favorite {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background-color: $color-primary-dark;
      cursor: pointer;
      &.active {
        background-color: $color-white;
      }
      .favorite-heart {
        padding-top: 7px;
      }
    }
  }
  .info {
    grid-area: info;
    text-align: left;
    .type,
    .duration {
      @include font-text;
      margin: -5px 0;
    }
    .name {
      @include font-button;
      font-size: 22px;
    }
  }
  .image {
    grid-area: image;
    border-radius: 13px;
    justify-self: center;
  }
}
