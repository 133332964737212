@import "../../../../../shared/style/typography";
@import "../../../../../shared/style/variables";

.breathing-text {
  @include font-black;
  font-size: 30px;
  text-align: left;
  background: $color-gradient-text-light $gradient-breathing-text repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  position: absolute;
  top: 0x;
  left: 25px;
  width: 400px;

  z-index: 999;
}

.fade-in {
  animation-name: fade;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-duration: 3s;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
}
