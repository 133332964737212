@import "../../../style/variables";
@import "../../../style/typography";

.numpad {
  width: 85%;
  margin: auto;

  padding-bottom: 2rem;

  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    ". . ."
    ". . ."
    ". . ."
    ". zero .";
  .zero {
    grid-area: zero;
  }
  .key {
    background-color: transparent;
    height: 64px;

    border: none;
    border-radius: 18px;
    flex: 1 0 25%;
    transition: background-color 0.1s ease-in-out;

    p {
      @include font-sub-heading;
      font-size: 48px;
    }
  }

  .key:hover {
    background-color: rgba($color-primary-light, 0.2);
  }
  .key:active {
    background-color: rgba($color-primary-dark, 0.2);
  }
  .backspace {
    grid-area: backspace;
  }
}
