@import "../../../../../shared/style/typography";

.qr-login-container {
  display: flex;
  flex-direction: column;
  @media (min-width: 864px) {
    flex-direction: row;
  }
  gap: 20px;
  .qr-login-instructions {
    @include font-regular;

    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 50%;
    text-align: center;
    @media (min-width: 864px) {
      text-align: left;
      align-items: flex-start;
    }
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
    h3 {
      @media (min-width: 864px) {
        text-align: left;
      }
    }
    ol {
      padding-inline-start: 20px;
      li {
        @include font-regular;
      }
    }
  }
  .qr-code {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    align-items: center;
    // fixed height to stop flicker on re-render
    height: 427px;
    .button-container {
      position: absolute;
      top: 147px;
    }
    .loading-container {
      position: absolute;
      top: 73px;
    }
  }
}
