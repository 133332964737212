@import "../../../../../../shared/style/variables";
@import "../../../../../../shared/style/typography";

.playlist-resources {
  text-align: left;
  display: flex;
  flex-direction: column;
  color: $color-primary-dark;
  .playlist-resources-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .title {
      @include font-heading;
      font-size: 30px;
    }
  }
  .playlist-resources-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
