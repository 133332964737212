@import "../../../../shared/style/typography";

.navigation-mobile-action-menu {
  margin-left: auto;
  .navigation-mobile-action-menu-item {
    width: 34px;
    margin-right: 20px;
  }
}

.navigation-mobile-wrapper {
  display: none;
  position: absolute;
  top: 58px;
  @include md {
    top: 83px;
  }
  left: 0;
  right: 0;
  height: calc(100vh - 58px);
  background-color: $color-white;
  z-index: 12;
  &.open {
    display: flex;
    flex-direction: column;
    position: fixed;
  }

  .navigation-menu-mobile {
    margin-top: 0;
    margin-bottom: 0;
    .navigation-menu-mobile-item {
      a {
        @include font-heading;
        @include font-black;
        @include x-large;
        padding: 0;
      }
      margin: 1.8rem 0;
      text-align: center;
    }
    .navigation-menu-mobile-item:nth-child(1) {
      a {
        color: $color-text-dark;
      }
    }
    .navigation-menu-mobile-item:nth-child(2),
    .navigation-menu-mobile-item:nth-child(4) {
      a {
        color: $color-text-blue;
      }
    }
    .navigation-menu-mobile-item:nth-child(3) {
      a {
        color: $color-text-orange;
      }
    }
    .navigation-menu-mobile-item:nth-child(5) {
      a {
        color: $color-text-purple;
      }
    }
    .navigation-menu-mobile-item:nth-child(6) {
      * {
        color: $color-text-teal;
      }
    }
  }

  .navigation-footer-mobile {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 2.1rem;
    @include font-heading;
    font-size: 12px;
    color: $color-primary-dark;
    .navigation-menu-mobile-footer-item {
      .navigation-menu-mobile-footer-item:after {
        position: absolute;
        content: ".";
        //padding-left: 1rem;
      }
      margin: 1rem 0;
      line-height: 19.2px;
      a {
        @include font-heading;
        font-size: 12px;
        text-align: left;
        vertical-align: middle;
        color: $color-primary-dark;
        padding: 0;
      }
    }
  }
  .navigation-footer-mobile li:after {
    content: ".";
    padding-left: 1.2rem;
    position: absolute;
    top: 55%;
    -webkit-transform: translateY(-75%);
    -moz-transform: translateY(-75%);
    -o-transform: translateY(-75%);
    -ms-transform: translateY(-75%);
    transform: translateY(-75%);
  }
  .navigation-footer-mobile li:nth-child(3):after {
    content: "";
  }
}
