@import "../../../shared/style/variables";
.modal-small {
  .share-container {
    display: flex;
    justify-content: flex-start;
    gap: 1.3rem;
    margin: 1rem 0 1.4rem;
    .share-button-label {
      margin-top: 0;
      font-size: 14px;
    }
  }

  .url-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid $color-primary-dark;
    border-radius: 32px;
    .url {
      text-align: left;
    }
    .copy-text {
      cursor: pointer;
      color: $color-text-blue;
    }
  }
}
