@import "variables";
@import "typography";
@import "buttons";

#CybotCookiebotDialogPoweredbyLink {
  display: none !important;
}

#CybotCookiebotDialog,
#CybotCookiebotDialogBody,
#CybotCookiebotDialogBodyContent,
#CybotCookiebotDialogBodyContentTitle,
#CybotCookiebotDialogBodyContentText {
  font-family: Lato, sans-serif !important;
  color: $color-primary-main;
  a {
    color: $color-primary-main;
  }
  h2 {
    color: $color-primary-main;
  }
}

#CybotCookiebotDialogBodyButtonDecline {
  @include button;
  margin-left: 0;
}
#CybotCookiebotDialogBodyButtonAccept {
  @include gold-button;
}
#CybotCookiebotDialogBodyButtonAccept,
#CybotCookiebotDialogBodyButtonDecline {
  width: calc(100% - 3.6rem) !important;

  margin-bottom: 0.6rem;
  font-family: Lato, sans-serif !important;
  font-size: 20px;
}
