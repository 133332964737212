@import "../../../../shared/style/typography";

.login-page {
  padding-bottom: 12em;
  .white {
    width: 100vw;
    background-color: $color-white;
    padding-top: 32px;
  }
}
