@import "../../../shared/style/variables";
@import "../../../shared/style/typography";

.notification {
  position: fixed;
  top: 30px;
  right: 30px;
  display: flex;

  height: 141px;
  width: 396px;
  border-radius: 20px;
  background-color: $color-text-purple;

  transition: 0.4s all ease-in;

  z-index: 9999;
  &.hidden {
    transform: translateX(150%);
  }
  .notification-icon {
    margin: 1.2rem;
    object-fit: cover;
    border-radius: 20px;
  }
  .notification-content {
    padding: 1rem 1.2rem 0 0;
    display: flex;
    flex-direction: column;
    .notification-title {
      @include font-heading;
      font-size: 20px;
      margin-bottom: 0;
    }
    .notification-body {
      @include font-text;
      margin-top: 0.3rem;

      font-size: 18px;
      line-height: 25px;
      text-overflow: ellipsis;
    }
  }
  .close-button {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 25px;
    height: 24px;
    border: 3px solid $color-primary-dark;
    border-radius: 50%;
    cursor: pointer;
  }
  .close-button:before,
  .close-button:after {
    content: "";
    position: absolute;
    left: 11px;
    top: 6px;
    height: 12px;
    width: 3px;
    background-color: $color-primary-dark;
  }
  .close-button:before {
    transform: rotate(45deg);
  }
  .close-button:after {
    transform: rotate(-45deg);
  }
}
