@import "../../../style/variables";
@import "../../../style/typography";
.drop-down {
  position: relative;
  @include md {
    width: 349px;
  }
  height: 46px;

  color: $color-primary-dark;
  background-color: $color-white;
  border-radius: 29px;
  z-index: 4;

  &.open {
    .drop-down-title {
      background: white;
      border-radius: 22px 22px 0 0;
      .drop-down-arrow {
        rotate: 0deg;
      }
    }
  }
  &.with-selection {
    background-color: $color-white;
  }
  .drop-down-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    padding: 0 16px;
    cursor: pointer;
    z-index: 6;
    .drop-down-arrow {
      width: 24px;
      margin: auto 0;
      z-index: 7;
    }
    .title-text {
      @include font-bold;
      margin: auto;
      font-size: 18px;
      line-height: 19px;
      z-index: 7;
    }
  }
  .options {
    width: 100%;
    height: 0;
    position: absolute;
    border-radius: 0 0 22px 22px;
    background: $color-white;

    &.single {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      margin-top: -11px;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 40vh;
    }
    &.multi {
      margin-top: -22px;
      overflow: hidden;
      text-align: left;
    }

    -webkit-transition: height 0.2s ease;
    -moz-transition: height 0.2s ease;
    -ms-transition: height 0.2s ease;
    -o-transition: height 0.2s ease;
    transition: height 0.2s ease;

    // single select
    .option {
      padding: 0 30px;
      cursor: pointer;
    }
    .option:hover,
    .selected {
      color: $color-white;
      background-color: $color-primary-blue;
    }
    .option-text {
      @include font-regular;
      font-size: 18px;
    }
    // multi select
    .multi-option {
      padding: 0 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      p {
        margin: 7px 0;
      }
    }

    .multi-option:first-of-type {
      padding-top: 28px;
    }
    .multi-option:last-of-type {
      padding-bottom: 19px;
    }
    .radio {
      width: 8px;
      height: 8px;
      border: 1px solid $color-primary-dark;
      border-radius: 50%;
    }
    .multi-option-text {
      @include font-regular;
      font-size: 15px;
      padding-left: 8px;
    }
    .multi-selected {
      .radio {
        background-color: $color-primary-dark;
      }
    }
  }
  + .error-icon {
    top: 10px;
    right: 53px;
    z-index: 4;
  }
  .clear-selection {
    @include font-bold;
    color: $color-button-link;
    border-radius: 22px 22px 0 0;
    text-decoration: underline;
    text-align: center;
    font-size: 16px;
    cursor: pointer;

    margin-top: 0;
    padding-top: 16px;
    position: absolute;
    bottom: -2px;
    right: 52px;
  }
  ::-webkit-scrollbar {
    height: 11px !important;
    width: 11px;
    background: transparent;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid white;
  }
}
