@import "../../../shared/style/variables";
.whatsapp {
  @include md {
    width: 136px;
    height: 46px;
  }
  width: 48px;
  height: 48px;
  // positioning
  float: right;
  position: fixed;
  bottom: 60px;
  right: 0;

  margin: 10px 20px;
  // styling
  background-color: rgb(7, 94, 84);
  color: rgb(255, 255, 255);
  border: 0;
  border-radius: 25px;
  z-index: 11;
  // transition
  transition-delay: 1s;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-property: opacity;

  .message-us {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin: 0 0;
  }
  .message-us-button-container {
    width: 100%;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    position: relative;
  }
  .message-us-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 15px;
    color: rgb(255, 255, 255);
    border-radius: 25px;
    font-size: 16px;
    font-family: Arial, sans-serif;
    font-weight: 500;
    line-height: 0;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .message-us-button-logo {
    min-width: 20px;
    display: block;
    margin: auto;
    @include md {
      margin: 0 8px 0 0;
    }
  }
  .message-us-button-label {
    display: none;
    @include md {
      display: block;
    }
  }
}
