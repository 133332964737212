@import "../../../shared/style/variables";

.marketing-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  gap: 1rem;

  margin: auto 1rem;
  padding-bottom: 4rem;
  @include md {
    flex-direction: row;
    width: 80%;
    margin: auto;
  }

  @include lg {
    width: 72rem;
    max-width: 90%;
  }

  &.reverse {
    @include md {
      flex-direction: row-reverse;
    }
  }

  .marketing-image {
    flex: 0 1 50%;
    object-fit: cover;
    border-radius: 22px;
    @include md {
      width: 50%;
      height: auto;
    }
  }

  .marketing-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 50%;

    @include md {
      text-align: left;
    }
    .title {
      color: $color-section-mid;
    }
  }
}
