@import "../../../../../shared/style/variables";

.try-moshi-image-wrapper {
  position: relative;
  display: flex;

  @include sm {
    margin-top: 3rem;
  }
  .try-moshi-sun-lines {
    position: absolute;
    display: none;
    @include md {
      display: block;
    }
    &.top {
      right: 0;
      top: 0;
      transform: translate(25%, -25%);
    }
    &.bottom {
      left: 0;
      bottom: 0;
    }
  }
  .phone-image-container {
    @include sm {
      margin: auto;
    }
    .try-moshi-phone-image {
      position: relative;
      z-index: 2;
      height: 100%;

      @include lg {
        padding-left: 0;
      }
    }
  }

  .try-moshi-phone-shadows {
    display: none;
    @include lg {
      display: block;
    }

    position: absolute;
    top: 54px;
    left: 54px;
  }
}
