@import "variables";

@mixin small {
  font-size: 0.9rem;
}
@mixin regular {
  font-size: 1rem;
}
@mixin large {
  font-size: 1.2rem;
}
@mixin x-large {
  font-size: 2.6rem;
  line-height: 40.8px;
}

.small {
  @include small;
}
.regular {
  @include regular;
}
.large {
  @include large;
}
.x-large {
  @include x-large;
}

@mixin font-light {
  font-weight: 300;
}
@mixin font-regular {
  font-weight: 400;
}
@mixin font-bold {
  font-weight: 600;
}
@mixin font-black {
  font-weight: 900;
}
@mixin font-black-italic {
  font-weight: 900;
  font-style: italic;
}

.font-light {
  @include font-light;
}
.font-regular {
  @include font-regular;
}
.font-bold {
  @include font-bold;
}
.font-black {
  @include font-black;
}
.font-black-italic {
  @include font-black;
}

@font-face {
  font-family: "chromatica";
  font-weight: 500;
  font-style: normal;
  src: url("/fonts/Chromatica-Regular.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Chromatica-Regular.ttf") format("truetype"),
    url("/fonts/Chromatica-Regular.woff2") format("woff2"),
    url("/fonts/Chromatica-Regular.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "chromatica";
  font-weight: 600;
  font-style: normal;
  src: url("/fonts/Chromatica-Bold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Chromatica-Bold.ttf") format("truetype"),
    url("/fonts/Chromatica-Bold.woff2") format("woff2"),
    url("/fonts/Chromatica-Bold.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "chromatica";
  font-weight: 700;
  font-style: normal;
  src: url("/fonts/Chromatica-Black.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Chromatica-Black.ttf") format("truetype"),
    url("/fonts/Chromatica-Black.woff2") format("woff2"),
    url("/fonts/Chromatica-Black.woff") format("woff");
  font-display: swap;
}

@mixin font-heading {
  font-family: chromatica, "Lato Black", sans-serif;
  font-weight: 700;
}
@mixin font-sub-heading {
  font-family: chromatica, "Lato Black", sans-serif;
  font-weight: 600;
}
@mixin font-button {
  font-family: chromatica, "Lato Black", sans-serif;
  font-weight: 600;
}
@mixin font-text {
  font-family: "Lato", "Avenir", sans-serif;
}
@mixin font-mono {
  font-family: Monaco, "Courier New", Courier, monospace;
}
.font-heading {
  @include font-heading;
}
.font-sub-heading {
  @include font-sub-heading;
}
.font-button {
  @include font-button;
}
.font-text {
  @include font-text;
}
.font-mono {
  @include font-mono;
}

.text-italic {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: 600 !important;
}

@mixin text-center {
  text-align: center;
}
.center {
  @include text-center;
}
