@import "../../../style/variables";

.plus {
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 20px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    background-color: $color-primary-dark;
    transition: transform 0.25s ease-out;
    border-radius: 8px;
  }

  /* Vertical line */
  &:before {
    top: 0;
    left: 50%;
    width: 4px;
    height: 100%;
    margin-left: -2px;
  }

  /* horizontal line */
  &:after {
    top: 50%;
    left: 0;
    width: 100%;
    height: 4px;
    margin-top: -2px;
  }
}
.minus {
  &:before {
    transform: rotate(90deg);
  }
  &:after {
    transform: rotate(180deg);
  }
}
