@import "../../../../shared/style/variables";

.header-logo {
  width: $width-logo-mobile;
  @include md {
    width: $width-logo-desktop;
  }
  svg {
    width: inherit;
    fill: $color-primary-dark;
    image {
      fill: $color-primary-dark;
    }
  }
}
