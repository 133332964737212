@import "./variables";

.flow-widget {
  font-size: 0.9rem;
  letter-spacing: 0.3px;
  display: flex;
  flex-direction: row;
  margin-top: 1.6em;
  width: auto;
  justify-content: center;
  counter-reset: div;

  div {
    position: relative;
    color: rgba(255, 255, 255, 0.6);

    &:before {
      background-color: rgba(255, 255, 255, 0.6);
      @include font-bold;
      @include sm {
        content: counter(div);
      }

      counter-increment: div;
      display: inline-block;
      vertical-align: middle;
      font-size: 1rem;
      padding: 0.15rem;
      border-radius: 2em;
      bottom: 0.1em;
      position: relative;
      margin-right: 0.4em;
      color: $color-primary-main;
      width: 1.2em;
      line-height: 1.2em;
      list-style-type: decimal;
      list-style-position: inside;
    }

    &:after {
      color: white;
      content: ">";
      margin: 0 0.4em;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &.active {
      color: rgba(255, 255, 255, 1);
      &:before {
        background-color: white;
      }
    }
  }
}

hr.narrow {
  width: 5rem;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: $color-primary-main;
  opacity: 0.8;
  margin: 1rem auto;

  &.white {
    border-color: white;
    opacity: 1;
  }
}

.list-table {
  & > table {
    width: 100%;
    border-collapse: collapse;

    th {
      text-align: left;
    }

    & > tbody {
      & > tr {
        border-bottom: 1px solid #c0c1ff;

        & > td {
          padding: 0.2em 0.3em;
          @include md {
            padding: 0.8em 1em 0.8em 0;
          }
          font-size: 1.2rem;
          &:nth-child(1),
          &:nth-child(2) {
            word-break: break-all;
          }
        }

        & > #name {
          @include font-bold;
        }
        &:hover {
          background-color: #3e2694;
        }
      }
    }
  }

  .cell-header {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      padding: 0.4rem 0;
    }

    td {
      padding: 1rem;
    }

    .cell-header {
      display: inline-block;
      font-weight: bold;
      padding-right: 1em;
      font-size: 0.8em;
      min-width: 7em;
    }
  }
}
