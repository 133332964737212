@import "variables";
@import "typography";

.modal-small {
  &.form {
    top: 50%;
    height: 90vh;
  }
  form {
    padding: 0;
  }
  a {
    padding-top: 1em;
  }
  @include sm {
    width: 85%;
  }
  @include md {
    width: calc(566px - 70px);
  }
  display: flex;
  flex-direction: column;

  .title {
    @include font-bold;
    color: $color-primary-main;
    text-align: center;
  }

  .description {
    @include sm {
      padding: 0;
    }
    padding: 0 2em 0 2em;
    color: $color-primary-main;
  }
  .buttons {
    @include sm {
      margin: 30px 0 22px 0;
    }
    margin: 49px 0 27px 0;
    align-items: center;
  }
  .drop-down {
    background-color: rgba($color-primary-main, 0.3);
    &.open {
      background-color: transparent;
    }
  }
  .label {
    margin-bottom: 1rem;
  }
  .form-group,
  .data-group {
    margin-top: 2rem;
  }
}
