@import "./_typography.scss";
@import "./_variables.scss";

p {
  font-size: 22px;
  line-height: 1.4em;
}

a {
  color: $color-button-link;
  text-decoration: underline;
  &:hover {
    color: $color-dark-blue;
  }
}

h1,
h2,
h3 {
  @include font-heading;
  line-height: 1.3em;
  color: inherit;
  text-align: inherit;
  margin: 0 0 1rem 0;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 45px;
}

h2 {
  font-size: 34px;
}

h3 {
  @include font-sub-heading;
  font-size: 28px;
  margin-top: 1.6em;
  text-align: center;
}

button.link {
  color: $color-white;
  text-decoration: none;

  cursor: pointer;
  display: inline;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;

  &:hover {
    color: darken($color-white, 10%);
  }
  &.text-underlined {
    text-decoration: underline;
  }
}

ol {
  text-align: left;
  list-style-type: decimal;
  li {
    @include large;
    text-align: -webkit-match-parent;
    padding: 0 0.4em 0.3em;
    line-height: 1.3em;
    font-weight: 600;
  }
}
ul {
  list-style-type: disc;
  padding: 0;
  li {
    display: block;
    text-align: -webkit-match-parent;
    box-sizing: border-box;
    a {
      @include large;
      color: $color-white;
      padding: 0 1.6rem;
      line-height: 1.2rem;
      font-weight: 600;
      text-decoration: none;
    }
  }
}

.nav-link {
  position: relative;
  color: $color-primary-dark;
  margin-left: 0.6rem;
}

.tooltip-parent {
  position: relative;
}
.tooltip-parent .tooltip {
  @include font-text;
  visibility: hidden;
  background-color: rgba($color-white, 0.6);
  color: $color-primary-dark;

  font-size: 14px;
  text-align: center;
  padding: 0 1rem;
  border-radius: 18px;

  position: absolute;
  width: 75px;
  top: 110%;
  left: -37.5px;
}

.tooltip-parent:hover .tooltip {
  visibility: visible;
}
