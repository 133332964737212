@import "../../../style/variables";

.action-footer {
  background-color: $color-form-light;
  border-radius: 4rem 4rem 0 0;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;

  z-index: 6;
  padding-bottom: 1rem;
  @include md {
    padding-bottom: 0;
  }
  button {
    margin-bottom: 0;
  }
  &.hidden {
    position: absolute;
  }
}
