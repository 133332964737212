@import "../../../../shared/style/variables";

.navigation-menu-button {
  background-color: transparent;
  border: none;
  width: 18px;
  height: 24px;
  padding: 0;
  div {
    width: 100%;
    height: auto;
    display: block;
    vertical-align: middle;
    span {
      display: block;
      height: 2px;

      background-color: $color-white;
      color: $color-white;

      margin-bottom: 6px;
      border-radius: 30px;

      transform-origin: 4px 8px;
      -ms-transform-origin: center center;
      -o-transform-origin: center center;
      -webkit-transform-origin: center center;
      -moz-transform-origin: center center;
      -o-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
  }
}
.open {
  span {
    transform: scaleX(0);
  }
  span:first-of-type {
    transform: translateY(12px) rotate(-45deg);
    background-color: $color-primary-dark;
    color: $color-primary-dark;
  }
  span:last-of-type {
    transform: translateY(-4px) rotate(45deg);
    background-color: $color-primary-dark;
    color: $color-primary-dark;
  }
}
