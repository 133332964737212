@import "../../../../../shared/style/variables";
@import "../../../../../shared/style/typography";

.featured-section {
  max-width: 1024px;
  width: 90%;
  margin-bottom: 48px;

  .featured-section-header {
    display: flex;
    align-items: flex-end;
    padding: 0 26px;
    height: 104px;
    background-color: $color-section-pale;
    border-radius: 64px 64px 0 0;

    .featured-section-tab {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70px;
      width: 264px;
      border-radius: 32px 32px 0 0;
      color: $color-text-purple;
      &.selected {
        color: $color-text-dark;
        background-color: $color-section-mid;
      }
      .featured-section-text {
        display: flex;
        gap: 8px;
        h3 {
          margin: 0;
          font-size: 25px;
        }
      }
    }
  }
  .featured-section-content {
    background-color: $color-section-mid;
    padding: 72px 64px 60px;
    border-radius: 0 0 64px 64px;
  }
}
