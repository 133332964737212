@import "../../../../../shared/style/variables";
@import "../../../../../shared/style/typography";

.content-cell {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 206px;

  cursor: pointer;
  .favorite-heart {
    position: absolute;
    top: 16px;
    left: 16px;
    background-color: $color-primary-dark;

    padding: 4px;

    border-radius: 50%;
    &.active {
      background-color: $color-white;
    }
  }
  .cell-image {
    height: 206px;
    border-radius: 13px 13px 0 0;
  }
  .cell-info {
    display: flex;
    flex-direction: column;
    background-color: $color-off-white;

    height: 86px;
    padding: 10px 16px 12px;
    border-radius: 0 0 13px 13px;

    text-align: left;
    p {
      text-overflow: ellipsis;
    }
    p,
    ul,
    li {
      font-size: 13px;
      margin: 0 0 4px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: normal;
    }
    h5 {
      @include font-bold;
      font-size: 16px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      overflow: hidden;
      text-overflow: ellipsis;

      min-height: 42px;
      margin: 0 0 6px;
    }
  }
}
