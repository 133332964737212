@import "../../../../shared/style/typography";

.navigation-menu {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  -webkit-box-direction: normal;
  -webkit-font-smoothing: antialiased;
}

.navigation-main-menu {
  display: flex;
  flex-direction: row;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;

  .navigation-main-menu-item {
    @include font-heading;
    @include large;
    display: inline-block;
  }
}

.navigation-action-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1.9rem;
  gap: 1.9rem;

  .navigation-action-menu-item {
    cursor: pointer;
    height: $height-icon-button;
    img {
      width: inherit;
      height: inherit;
    }
  }
}
