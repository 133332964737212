@import "../../../../../../shared/style/variables";
@import "../../../../../../shared/style/typography";

.details {
  @include font-regular;
  display: flex;
  flex-direction: column;
  @include lg {
    padding: 20% 0 0 18px;
  }
  .category {
    font-size: 16px;
    letter-spacing: 0.19px;
    margin: 0;
  }
  .title {
    font-size: 22px;
    letter-spacing: 0.23px;
    line-height: 24px;
    margin-bottom: 12px;
  }
  .narrator {
    font-style: italic;
  }
  .narrator,
  .artist {
    font-size: 13px;
    letter-spacing: 0.16px;
    margin-top: 0;
  }
  .favorite,
  .share {
    margin: 1rem auto;
    border-radius: 50%;
    background-color: $color-primary-dark;
    cursor: pointer;
    &.active {
      background-color: $color-white;
    }

    .favorite-heart {
      padding: 5px;
      display: block;
      width: 20px;
    }

    .share-icon {
      margin: 0 2px 3px;
      padding: 5px;
      display: block;
      width: 20px;
    }
  }
  .btn {
    width: 120px;
    align-self: center;
    margin: 1rem 0;
  }

  .description {
    font-size: 18px;
    letter-spacing: 0.19px;
  }
}
