@import "../../../shared/style/_variables";

.progress-bar {
  background-color: $color-white;
  height: 12px;
  border-radius: 10rem;
  @include sm {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @include md {
    height: 10px;
    margin: 0.6rem 7rem 2rem;
  }
  .progress-bar-fill {
    background-color: $color-primary-dark;
    height: 100%;
    border-radius: 10rem;
    transition: width 3s ease;
  }
}
