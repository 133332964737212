@import "../../../../../shared/style/variables";
@import "../../../../../shared/style/typography";

.try-moshi {
  display: flex;
  width: 100%;
  padding-bottom: 8.5rem;
  overflow: hidden;

  @include sm {
    flex-direction: column-reverse;
  }
  @include md {
    padding-top: 6rem;
    flex-direction: row;
    justify-content: space-evenly;
  }
  @include lg {
    padding-top: 4rem;
  }

  .try-moshi-features-wrapper {
    display: flex;
    flex-direction: column;
    @include sm {
      padding: 0 30px;
      gap: 15px;
    }
    @include md {
      width: 30%;
      gap: 40px;
    }

    margin-top: 3rem;
    .try-moshi-features-title {
      @include font-heading;
    }
    .try-moshi-feature {
      display: flex;
      gap: 20px;
      .try-moshi-feature-text {
        @include font-regular;
        font-size: 14px;
        @include md {
          font-size: 20px;
        }
        margin: 0;
      }
    }
  }
  .btn {
    width: fit-content;
  }
  .desktop {
    display: none;
    @include md {
      display: block;
    }
  }
  .mobile {
    display: block;
    margin: 0 auto;
    @include md {
      display: none;
    }
  }
}
