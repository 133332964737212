@import "../../../../../../shared/style/variables";
@import "../../../../../../shared/style/typography";

.track-list {
  padding-bottom: 72px;
  .playlist-info {
    display: flex;
    flex-direction: row;
    padding-left: 24px;
  }
  li:first-of-type {
    display: inherit;
  }
  li {
    @include font-sub-heading;
    font-size: 18px;
    display: list-item;
    color: $color-primary-dark;
    padding-right: 1.7rem;
  }
  hr {
    border: 1px solid $color-primary-dark;
  }
  .separator {
    display: block;
    height: 1px;
    background-color: $color-primary-dark;
  }
}
